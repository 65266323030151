import "./App.css";
import { useMemo } from "react";
import * as anchor from "@project-serum/anchor";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  const settings = {
    infinite: true,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    swipeToSlide: true,
    cssEase: "ease-in-out"
  };
  const settingsmobile={
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    swipeToSlide: true,
    cssEase: "ease-in-out"
  };
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    menu!.classList.toggle("open-menu");
  }
  return (
    <div className="main">
      <nav className="hide-800">
        <a href="#home">Home</a>
        <a href="#collection">Collection</a>
        <a href="#faq">FAQ</a>
        <a href="#howtobuy">How to Buy</a>
        <a href="#roadmap">Road Map</a>
        <a href="https://www.instagram.com/p/Ckl_G6AJnkY/?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><img src="/icons/instagram.svg" className="socicon"></img></a>
        <a href="https://t.me/+lG400fi9VJQ2NmRh" target="_blank" rel="noreferrer"><img className="socicon" src="/icons/telegram.svg"></img></a>
      </nav>
      <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <div className="mobile-nav" id="mobileNavContainer">
        <div className="close-button" id="menuIcon" onClick={() => toggleMenu()}>
          <img src="/icons/close.svg" alt="" />
        </div>
        <a href="#home" onClick={() => toggleMenu()}>Home</a>
        <a href="#collection" onClick={() => toggleMenu()}>Collection</a>
        <a href="#faq" onClick={() => toggleMenu()}>FAQ</a>
        <a href="#howtobuy" onClick={() => toggleMenu()}>How to Buy</a>
        <a href="#roadmap" onClick={() => toggleMenu()}>Road Map</a>
        <div className="flex">
          <a href="https://www.instagram.com/p/Ckl_G6AJnkY/?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><img src="/icons/instagram.svg" className="socicon"></img></a>
          <a href="https://t.me/+lG400fi9VJQ2NmRh" target="_blank" rel="noreferrer"><img className="socicon" src="/icons/telegram.svg"></img></a>
        </div>

      </div>
      <div className="first" id="home">
        <div className="top-section">
          <div className="center-container">
            <h1>Jupiter NFT Project</h1>
            <img className="mainlogo" src="logo.png" alt="" />
          </div>

        </div>
        <div className="carousel hide-800">
          <Slider {...settings}>
            <img src="/assets/1.png" alt="" />
            <img src="/assets/2.png" alt="" />
            <img src="/assets/3.png" alt="" />
            <img src="/assets/4.png" alt="" />
            <img src="/assets/5.png" alt="" />
            <img src="/assets/6.png" alt="" />
            <img src="/assets/7.png" alt="" />
            <img src="/assets/8.png" alt="" />
            <img src="/assets/9.png" alt="" />
            <img src="/assets/10.png" alt="" />
            <img src="/assets/11.png" alt="" />
            <img src="/assets/12.png" alt="" />
            <img src="/assets/13.png" alt="" />
            <img src="/assets/14.png" alt="" />
            <img src="/assets/15.png" alt="" />
          </Slider>
        </div>
        <div className="carousel hide-desk">
          <Slider {...settingsmobile}>
            <img src="/assets/1.png" alt="" />
            <img src="/assets/2.png" alt="" />
            <img src="/assets/3.png" alt="" />
            <img src="/assets/4.png" alt="" />
            <img src="/assets/5.png" alt="" />
            <img src="/assets/6.png" alt="" />
            <img src="/assets/7.png" alt="" />
            <img src="/assets/8.png" alt="" />
            <img src="/assets/9.png" alt="" />
            <img src="/assets/10.png" alt="" />
            <img src="/assets/11.png" alt="" />
            <img src="/assets/12.png" alt="" />
            <img src="/assets/13.png" alt="" />
            <img src="/assets/14.png" alt="" />
            <img src="/assets/15.png" alt="" />
          </Slider>
        </div>
        <div className="topright">
          <ThemeProvider theme={theme}>
            <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect>
                <WalletDialogProvider>
                  <Home
                    candyMachineId={candyMachineId}
                    connection={connection}
                    txTimeout={DEFAULT_TIMEOUT}
                    rpcHost={rpcHost}
                    network={network}
                    error={error}
                  />
                </WalletDialogProvider>
              </WalletProvider>
            </ConnectionProvider>
          </ThemeProvider>
          <CrossmintPayButton className="crossmint"
            clientId="3a23afba-f290-4583-990a-2168efce7779"
            mintConfig={{ "type": "candy-machine" }}
          />
        </div>

      </div>


      <div className="collection" id="collection">
        <div className="left">
          <h2>COLLECTION</h2>
          <p>
            The Jupiter NFT project collection is built on three pillars: community, culture and utility. The idea is to integrate these pillars into a new digital culture driven by a common interest where Art is the link that provides functionality.
            <br /><br />
            The intension behind the Jupiter NFT Project is to create a community-driven program where businesses, consumers, and holders NFTs can all participate in a common goal for creating wellbeing.
            <br /><br />
            NFTs’ holders benefit by being part of a collective network.
            <br /><br />
            One of the NFTs objectives is to be a marketing tool for business. As for individuals, the digital art is also a desirable collectible to own, and that will provide them with rewards and long-term benefits.
            <br /><br />
            Businesses participating in the project can offer a variety of incentives to customers owning an NFT, and therefore increase customer loyalty. In exchange for the benefits provided to consumers, the Jupiter NFT Project will promote the participating businesses on social media and other platforms.
            <br /><br />
            The utility or reward behind the NFTs varies according to each business model; these can go from straight discounts on items on a precise day or time, to special markdown inventory discounts, free valet parking, exclusive access to event, VIP tickets, free admission to sport events, priority reservations at a restaurant, entrance tickets to a conference, etc.
            <br /><br />
            The possibilities are endless. The idea is to create a bond in the community where everyone benefits.

          </p>
        </div>
        <div className="right">
          <div className="irreg">
            <img src="/assets/10.png" className="ireg1" alt="" />
            <img src="/assets/2.png" alt="" />
          </div>
          <div className="irreg">
            <img src="/assets/1.png" className="ireg2" alt="" />
            <img src="/assets/4992.png" className="ireg3" alt="" />
          </div>
          <div className="irreg">
            <img src="/assets/5.png" className="ireg4" alt="" />
          </div>
        </div>

      </div>

      <div className="faq" id="faq">
        <h2>FAQ</h2>

        <div className="question">
          <h3>Can I use my Credit Card to pay?</h3>
          <p>
            Yes. You can use https://crossmint.io/  it will be minted on the Solana blockchain and sent to your Solana wallet.
          </p>
        </div>
        <div className="question">
          <h3>Can I purchase the NFT using Cryptocurrency?</h3>
          <p>
            Yes, you will need a Solana wallet and SOL tokens, details on how to create a digital wallet are listed below. SOL tokens are available in most exchanges, Coinbase.com is a simple to use exchange.</p>
        </div>
        <div className="question">
          <h3>What is an NFT?</h3>
          <p>
            In simple terms, an NFT (Non-Fungible Token) is a digital file with a smart contract that contains a one-of-a-kind code and is permanently recorded on a blockchain. Accordingly, an NFT cannot be altered, thus guaranteeing authenticity and provenance.
            <br />
            A blockchain is a decentralized, digital record that cannot be forged or counterfeited. Each time a transaction occurs, it is recorded on the blockchain ledger, and is transparent to the public.
            <br />
            No two NFTs are alike, thus providing exclusivity, scarcity, and value in the digital realm.
          </p>
        </div>
        <div className="question">
          <h3>Why purchase an NFT?</h3>
          <p>
            When purchasing an NFT, the buyer is receiving a unique and secure digital asset, such as a piece of digital artwork, with a unique serial number, backed by the blockchain. Just like buying other collectables, such as coins or baseball cards, you are purchasing a unique asset that may increase or decrease in value and may be sold or traded.
          </p>
        </div>
        <div className="question">
          <h3>How do I purchase an NFT?</h3>
          <p>
            Three Steps: (1) Create a Digital Wallet, (2) Connect the wallet to Jupiternftproject.com, and (3) click to buy the NFT. The NFT will then be delivered to your Solana-supported wallet.
          </p>
        </div>
        <div className="question">
          <h3>What is a Digital Wallet?</h3>
          <p>
            A Digital Wallet is a secure place to store cryptocurrency and NFTs.
          </p>
        </div>
        <div className="question">
          <h3>How do I create a Digital Wallet?</h3>
          <p>
            If you are new to NFTs, we suggest using the Phantom Digital Wallet (although almost any Digital Wallet compatible with Solana will work). Go to <a href="https://phantom.app">https://phantom.app</a> and follow the instructions to add Phantom as an extension to your browser.
            <br />
            Be sure to save your wallet address, secret recovery code, and password in a safe place because there is no centralized authority to perform a password reset for you. Your wallet is only identified by its address, not by your email or phone number, so don&apos;t forget your address, especially if your computer or device is lost or crashes.
          </p>
        </div>
        <div className="question">
          <h3>What wallets do you support?</h3>
          <p>
            We recommend the Phantom wallet. We also support Slope, Solflare, Torus, Ledger or Sollet.
          </p>
        </div>
        <div className="question">
          <h3>WHow do I connect my Digital Wallet to JupiterNFTproject.com?</h3>
          <p>
            Click the “CONNECT WALLET” button, and then authorize .com to connect to your wallet.
          </p>
        </div>
        <div className="question">
          <h3>What is Solana?</h3>
          <p>
            Solana works on a Proof-of-Stake consensus mechanism. It is widely accepted that Proof-of-Stake consensus mechanisms exert much less energy than Proof-of-Work (i.e., Ethereum). Further, Solana provides a faster experience than other blockchain protocols, and thus, boasts a broad base of users and developers.
          </p>
        </div>
        <div className="question">
          <h3>What wallets do you support?</h3>
          <p>
            We recommend the Phantom wallet. We also support Slope, Solflare, Torus, Ledger or Sollet.
          </p>
        </div>
        <div className="question">
          <h3>Do I need to add SOL Cryptocurrency to my Digital Wallet?</h3>
          <p>
            No. You can purchase Jupiter NFTs using a credit card. However, you still need to connect your wallet to receive your NFT.
          </p>
        </div>
        <div className="question">
          <h3>How can I see my NFTs?</h3>
          <p>
            Open your Phantom wallet and click on the ⊞ icon to view your artwork.
          </p>
        </div>
        <div className="question">
          <h3>Can I gift an NFT?</h3>
          <p>
            Yes.
          </p>
        </div>
        <div className="question">
          <h3>What is a smart contract royalty?</h3>
          <p>
            NFTs are designed with a small resale price or transaction fee if you resell them. You can always send an NFT as a gift without incurring any fee, but if you resell the NFT to a third party on the blockchain, there will be a 10% transaction fee.
          </p>
        </div>
        <div className="question">
          <h3>How can I resell my NFTs?</h3>
          <p>
            Once you have purchased an NFT, you can offer it for resale on many NFT platforms connected to Solana, including Solsea and Magic Eden, by connecting your wallet there. Note that different platforms may take a percentage of the resale, in addition to the smart contract royalty. need a mobile Solana wallet application as well, such as Solflare (available for iOS and Android) or Phantom (also available for iOS and Android).
          </p>
        </div>
        <div className="question">
          <h3>What internet browser can I use?</h3>
          <p>
            On Windows and Mac you may use Chrome, Firefox, Edge or Brave. On mobile you can use any mobile browser, but you will need a mobile Solana wallet application as well, such as Solflare (available for iOS and Android) or Phantom (also available for iOS and Android).          </p>
        </div>
        <div className="question">
          <h3>How do I purchase an NFT on mobile?</h3>
          <p>
            You will need a mobile wallet, such as Solflare (available for iOS and Android) or Phantom (also available for iOS and Android). Then you will need to copy your Solana wallet&apos;s public address to the clipboard on your phone with the 📋 icon. Then when you go to purchase the NFT on JupiterNFTproject.com from a mobile device, you will be prompted to enter your wallet address. Please be sure to enter your wallet address exactly (which is why we recommend copying and pasting); if you get even one character wrong your NFT will not reach you and we cannot retrieve it.

          </p>
        </div>
        <div className="question">
          <h3>How long does an NFT take to deliver?</h3>
          <p>
            Once you purchase an NFT, it will appear in your wallet in a matter of seconds to a few minutes, depending on the number of concurrent transactions on the Solana blockchain.
          </p>
        </div>
        <div className="question">
          <h3>Why is an NFT collectible?</h3>
          <p>
            When something becomes valuable and unique, it is usually worth collecting too!. While in modern days collectors have stuck to physical items such as paintings, coins, and many others, now the available spectrum of collectible items is expanding to the digital space at a fast rate thanks to NFTs.
            <br />
            NFTs allows to collect anything that is digital using blockchain technology. Some NFTs can even function as a digital twin of some sort and work in tandem with a physical item. While it may seem weird for some people to collect a digital file that can be copied, emailed, downloaded etc. The Non Fungible Tokens (NFTs) work as unique identifiers of digital files that can be used to trace their history and proof ownership. The transactions are registered in a decentralized ledger known as a blockchain and according to the rules established in a smart contract.
            <br />
            Digital collectible files are readily available for anyone through NFTs, and they can be collected, traded, invested in, and even find other utilities in the context of video games or the metaverse
          </p>
        </div>
        <h3>Need more help? support@jupiternftproject.com</h3>
      </div>

      <div className="howtobuy" id="howtobuy">
        <h2>How to buy</h2>
        <div className="question">
          <h3>Why is an NFT collectible?</h3>
          <p>
            To store your new NFT, you will need a cyrpto wallet on the Solana blockchain. We recommend the Phantom wallet. Visit <a href="https://phantom.app">https://phantom.app</a> from a Mac or Windows PC to add the Phantom wallet extension to a supported web browser (Brave, Chrome, Firefox, or Edge). Once installed, you&apos;ll be prompted to create a new wallet. Be sure to keep your secret recovery code and password in a safe place, as they cannot be recovered later.
          </p>
        </div>
        <div className="question">
          <h3>Step 2 - Connect your wallet to Jupiternftproject.com</h3>
          <p>
            You must connect your crypto wallet with Jupiternftproject NFT marketplace. Click the [Connect wallet] button and approve the connection. After you purchase your NFT, you can see it in the Tokens section of your Phantom wallet.
          </p>
        </div>
        <div className="question">
          <h3>Step 3 - Purchase with a credit card or Sol token</h3>
          <p>
            NFTs on Jupiternftproject.com are available for purchase with Solana wallet using SOL tokens, NFTs are also available for purchase with credit card using https://crossmint.io/ Simply enter your credit card details and depending on blockchain congestion your NFT will arrive in your Solana wallet in a few minutes.
          </p>
        </div>
      </div>
      <h2 className="roadmaptitle" id="roadmap">Roadmap</h2>
      <img src="roadmap.png" className="roadmapimg" alt="" />
      <h5 className="copyright">Copyright © 2022 Jupiter NFT Project All Rights Reserved</h5>
      <h5 className="copyright"><a href="./TermsofService.pdf" target="_blank">Terms of Service</a></h5>
      
    </div>

  );
};

export default App;
